import React from 'react';
import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import { Freya } from '../src/components/Freya/Freya';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import SEO from '../src/helpers/seo';

import './traslados.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query traslados($locale: String!) {
    allTrasladosSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allTrasladosImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allTrasladosTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          sub_title
          description
        }
      }
    }
    allTrasladosBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allTrasladosCardsBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          ctaText
          cta
          popup_title
          popup_content
          popupCta
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allTrasladosRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Traslados extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allTrasladosTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allTrasladosTitleBlock.edges[0].node.sub_title,
      image: {
        url: this.props.data.allTrasladosImageBlock.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allTrasladosImageBlock.edges[0].node.alt,
      },
    };

    const contentData = {
      title: this.props.data.allTrasladosTitleBlock.edges[0].node.description,
    };

    const PrepareFreya = src => ({
      cards: src.edges
        .map(e => {
          if (e.node.popupCta) {
            return {
              image: e.node.localImage.childImageSharp.fluid,
              altImage: e.node.alt,
              imgsize: 'small',
              title: e.node.title,
              description: e.node.description,
              twoButton: true,
              popupType: true,
              buttons: {
                size: 'small',
                color: 'orange',
                ctaText: e.node.ctaText,
                cta: e.node.cta,
                ctaText2: e.node.popupCta,
                color2: 'white',
                size2: 'small',
                popup: {
                  enable: true,
                  textpop: e.node.popup_title + e.node.popup_content,
                },
              },
            };
          }
          return {
            image: e.node.localImage.childImageSharp.fluid,
            altImage: e.node.alt,
            imgsize: 'small',
            title: e.node.title,
            description: e.node.description,
            buttons: {
              size: 'alone',
              color: 'orange',
              ctaText: e.node.ctaText,
              cta: e.node.cta,
            },
          };
        })
        .filter(e => e.image !== undefined),
    });

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allTrasladosSeoBlock.edges[0].node._0.title}
          description={this.props.data.allTrasladosSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allTrasladosRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allTrasladosImageBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="traslados-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allTrasladosBreadcrumbBlock.edges[0].node.name}
              />
              <H3 data={contentData} />
              <Freya data={PrepareFreya(this.props.data.allTrasladosCardsBlock)} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Traslados;
